import React from "react";

function MapBackgroundLayer() {

    return(
        <div className="mapBGLayer">
            <div className="mapBackgroundImage"/>
        </div>
    )
}

export default MapBackgroundLayer;
